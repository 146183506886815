export default class TrainingEvent {
  constructor(organization, information, eventType, eventTypeOther, location, fromDate, toDate, contactName, contactEmail, contactPhone, howFound) {
    this.organization = organization
    this.information = information
    this.eventType = eventType
    this.eventTypeOther = eventTypeOther
    this.location = location
    this.fromDate = fromDate
    this.toDate = toDate
    this.contactName = contactName
    this.contactEmail = contactEmail
    this.contactPhone = contactPhone
    this.howFound = howFound
  }
}