import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {   
    verify(token) {
        return apiClient.post('api/v1/recaptcha/verify/' + token)
    }
}

