<template>
  <Toast />
  <div class="container">

    <div class="message" v-show="showMessage">
      Thank you for the enquiry.  We will be in touch shortly.
    </div>

    <div v-show="!showMessage">
      <h2>Training Enquiry Form</h2>
<!-- Organization -->
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-6"  :class="{ valid: !v$.organization.$error && v$.organization.$dirty, error: v$.organization.$error }">
          <label for="Organization"> * Name of Organization</label> 
          <InputText id="Organization" class="form-control" v-model="v$.organization.$model" />
          <small class="error" v-for="error in v$.organization.$errors" :key="error.$uid" >{{ error.$message }}</small>
        </div>
      </div>
  <!-- Information -->
      <div class="p-col-12 p-md-8" :class="{ valid: !v$.information.$error && v$.information.$dirty, error: v$.information.$error }">
        <label for="Information">* Information about the event:</label>
        <Textarea id="Information" class="form-control" v-model="v$.information.$model" maxlength="2000" rows="10" />
        <small v-if="v$.information.$model">Character (max is {{v$.information.maxLengthValue.$params.max}}): {{v$.information.maxLengthValue.$params.max - v$.information.$model.length}} remaining</small> <small class="error" v-for="error in v$.information.$errors" :key="error.$uid" >{{ error.$message }}</small>
      </div>
  <!-- Training Event Type -->
        <div id="TrainingRequestType">
          <div class="p-formgroup-inline">
            <span class="title">* Event Type: </span>
              <div class="p-field-checkbox">
                <RadioButton v-model="trainingEvent.eventType" id="Training" value="Training" />
                <label for="Training">Training</label>
              </div>
              <div class="p-field-checkbox">
                <RadioButton v-model="trainingEvent.eventType" id="Conference" value="Conference" />
                <label for="Conference">Conference</label>
              </div>
              <div class="p-field-checkbox">
                <RadioButton v-model="trainingEvent.eventType" id="Other" value="Other" />
                <label for="Other">Other</label>
              </div>
        
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6" v-if="trainingEvent.eventType == 'Other'">
            <label for="EventTypeOther"> * Please describe the type of event:</label>
            <InputText id="EventTypeOther" class="form-control" v-model="trainingEvent.eventTypeOther" />
          </div>
  <!-- Dates -->
      <div id="TrainingRequestLocationAndDates" class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6" :class="{ valid: !v$.location.$error && v$.location.$dirty, error: v$.location.$error }">
          <label for="Location">* Location</label>
          <InputText id="Location" class="form-control" v-model="v$.location.$model" />
          <small class="error" v-for="error in v$.location.$errors" :key="error.$uid" >{{ error.$message }}</small>
        </div>
        <div class="p-field p-col-6 p-md-3" :class="{ valid: !v$.fromDate.$error && v$.fromDate.$dirty, error: v$.fromDate.$error }">
          <label for="DateFrom">* From: </label>
          <Calendar id="DateFrom" v-model="v$.fromDate.$model" :showIcon="true" />
          <small class="error" v-for="error in v$.fromDate.$errors" :key="error.$uid" >{{ error.$message }}</small>
        </div>
        <div class="p-field p-col-6 p-md-3" :class="{ valid: !v$.toDate.$error && v$.toDate.$dirty, error: v$.toDate.$error }">
          <label for="DateTo">* To: </label>
          <Calendar id="DateTo" v-model="v$.toDate.$model" :showIcon="true" />
          <small class="error" v-for="error in v$.toDate.$errors" :key="error.$uid" >{{ error.$message }}</small>
        </div>
      </div>

  <!-- Contact Information -->
      <div id="TrainingRequestContract" class="p-fluid p-formgrid p-grid">
        <div id="ContactName" class="p-field p-col" :class="{ valid: !v$.contactName.$error && v$.contactName.$dirty, error: v$.contactName.$error }">
          <label for="name"> *&nbsp;Contact Name</label>
          <InputText id="name" class="form-control" v-model="v$.contactName.$model" />
          <small class="error" v-for="error in v$.contactName.$errors" :key="error.$uid" >{{ error.$message }}</small>
        </div>
        <div id="EmailAddress" class="p-field p-col" :class="{ valid: !v$.contactEmail.$error && v$.contactEmail.$dirty, error: v$.contactEmail.$error }">
          <label for="address"> *&nbsp;Email Address</label>
          <InputText id="address" class="form-control" v-model="v$.contactEmail.$model" />
          <small class="error" v-for="error in v$.contactEmail.$errors" :key="error.$uid" >{{ error.$message }}&nbsp;</small>
        </div>
        <div id="PhoneNumber" clas="p-field p-col">
          <label for="number"> *&nbsp;Phone Number</label>
          <InputMask id="number" class="form-control" placeholder="+9 (999) 999-9999" mask="+9 (999) 999-9999" v-model="trainingEvent.contactPhone" />
        </div>
      </div>
  <!-- Information -->
      <div class="p-col-12 p-md-8" :class="{ valid: !v$.howFound.$error && v$.howFound.$dirty, error: v$.howFound.$error }">
        <label for="FindUs">* How did you hear about Jacinta Gallant's training and presentation services?:</label>
        <Textarea id="FindUs" class="form-control" v-model="v$.howFound.$model" maxlength="2000" rows="10" />
        <small v-if="v$.howFound.$model">Character (max is {{v$.howFound.maxLengthValue.$params.max}}): {{v$.howFound.maxLengthValue.$params.max - v$.howFound.$model.length}} remaining</small> <small class="error" v-for="error in v$.howFound.$errors" :key="error.$uid" >{{ error.$message }}</small>
      </div>
  <!-- Action button -->
      <div class="p-col-12 p-md-8">
        <Button :disabled="v$.$errors.length >= 1" label="Submit" icon="pi pi-send" iconPos="left" class="p-button-lg" @click="sendEnquiry()"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { useVuelidate } from '@vuelidate/core'
import { required, email, maxLength } from '@vuelidate/validators'

// Models
import TrainingEvent from '../schemas/TrainingEvent.js'

// API Services
import RecaptchaServices from '../services/RecaptchaServices.js'
import TrainingRequestServices from '../services/TrainingRequestServices.js'

// Primevue
import { useToast }   from 'primevue/usetoast'
import InputText      from 'primevue/inputtext'
import InputMask      from 'primevue/inputmask'
import RadioButton    from 'primevue/radiobutton'
import Calendar       from 'primevue/calendar'
import Textarea       from 'primevue/textarea'
import Button         from 'primevue/button'
import 'primeflex/primeflex.css'

export default {
  components: {
    InputText,
    InputMask,
    RadioButton,
    Calendar,
    Textarea,
    Button
  },
  setup() {
    const toast = useToast()
    const showMessage = ref(false)
    const environment = process.env.VUE_APP_ENVIRONMENT
    const trainingEvent = ref(new TrainingEvent())
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const recaptcha = async () => {
      await recaptchaLoaded()
      const token = await executeRecaptcha('TrainingEnquiry')
      RecaptchaServices.verify(token)
      .then((response) => {
        if(response.data.score >= 0.5 && response.data.success && response.data.action === "TrainingEnquiry") {
          if(trainingEvent.value.eventType != 'Other') { trainingEvent.value.eventTypeOther = '--' }
          
          const isComplete = (trainingRequest) => {
            for (const property in trainingRequest) {
              if(typeof trainingRequest[property] === 'undefined') { return false }
            }
            return true
          }

          if(isComplete(trainingEvent.value)) {
            TrainingRequestServices.createRequest(trainingEvent.value)
          .then((response) => {
            if(response.status != 200) {
              toast.add({severity:'info', summary: 'Information', detail: "Something went wrong", life: 5000})
            } else {
              trainingEvent.value = new TrainingEvent()
              showMessage.value = true
            }
          })
          .catch((error) => {
            toast.add({severity:'error', summary: 'Something Wrong', detail: error, life: 5000})
          })
          } else {
            toast.add({severity:'info', summary: 'Missing Information', detail: "Required fields must be completed prior to sending!", life: 5000})
          }
        } 
      })
    }

    const rules = {
      organization: { required },
      information: { required, maxLengthValue: maxLength(250) },
      location: { required },
      fromDate: { required },
      toDate: { required },
      contactName: { required },
      contactEmail: { required, email },
      howFound: { required, maxLengthValue: maxLength(250) }
    }

    const v$ = useVuelidate(rules, trainingEvent)

    const sendEnquiry = () => {
      recaptcha()
    }

    return {
      toast,
      environment,
      trainingEvent,
      sendEnquiry,
      recaptcha,
      v$,
      showMessage
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    display: grid;
    grid-template-columns: 100%;
    text-align: left;
  }
  .object {
    padding-top: 25px;
    text-align: left;
  }
  .title {
    padding-right: 25px;
  }
  #TrainingRequestType {
    padding: 25px 0px 25px 0px;
  }
  .valid input {
  border: 1px solid green;
}
.error input {
  border: 1px solid red;
}
.error {
  color: red;
}
.message {
  font-weight: bold;
  font-size: 2em;
}
</style>
