import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {   
    createRequest(trainingRequest) {
      return apiClient.post('api/v1/training-request/create', trainingRequest)
    }
}

